import React from "react";
import Container from "react-bootstrap/Container";
import { Alert } from "react-bootstrap";

export const About = () => (
  <Container>
    <h1>Sur le binet Album Promo</h1>

    <Alert variant="info">
      <Alert.Heading>Les binets APP et APV ont fusionnés</Alert.Heading>
      <p>
        Respectivement chargés des albums promo photo et albums promo vidéos,
        ils ont décidés de s'unir pour vous apporter un maximum de qualité.
      </p>
      <p>Site web développé par Hadrien Renaud et Titus Senez (X17)</p>
    </Alert>
  </Container>
);
