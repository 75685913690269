let valueAPI = "";
let valueClient = "";
if (process.env.NODE_ENV === "production") {
  //NODE_ENV is automatically put to production when on build
  valueAPI = "https://api-ap.binets.fr";
  valueClient = "https://ap.binets.fr";
} else {
  valueAPI = "http://localhost:8000";
  valueClient = "http://localhost:3000";
}
export const API_URL = valueAPI;
export const CLIENT_URL = valueClient;
export const APV_URL = "https://apv.binets.fr";
