import React from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";

export const Settings = () => (
  <Container>
    <Alert variant="warning">
      This page isn't build now. Please{" "}
      <Alert.Link as={Link} to="/">
        return to home
      </Alert.Link>
      .
    </Alert>
  </Container>
);
