import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { ROUTES } from "../services/routes";
import { API_URL, APV_URL } from "../config";
import { isLegit } from "../services/user";

export class Header extends React.Component {
  render = () => {
    return (
      <Navbar bg="dark" variant="dark" expand="lg" className="mb-4">
        <Container>
          <Navbar.Brand as={Link} to={ROUTES.HOME}>
            Albums Promo
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {isLegit(this.props.userData) && (
                <>
                  <Nav.Link as={Link} to={ROUTES.RESERVATION}>
                    Réserver un créneau
                  </Nav.Link>
                  <Nav.Link as={Link} to={ROUTES.PHOTOS}>
                    Mes photos
                  </Nav.Link>
                </>
              )}
              {this.props.userData && (
                <Nav.Link href={APV_URL}>APV en ligne</Nav.Link>
              )}
            </Nav>
            <Nav>
              <NavDropdown title="Plus" id="basic-nav-dropdown">
                {(this.props.userData.is_staff ||
                  this.props.userData.is_respo_studio) && (
                  <>
                    <NavDropdown.Item as={Link} to={ROUTES.AJOUT}>
                      Gérer les créneaux
                    </NavDropdown.Item>
                  </>
                )}
                <NavDropdown.Item as={Link} to={ROUTES.CONSIGNES}>
                  Consignes
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to={ROUTES.ABOUT}>
                  Le Binet AP
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href={`${API_URL}/logout?next=front`}>
                  Déconnexion
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  };
}
