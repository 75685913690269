import React from "react";
import { Home } from "./pages/Home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Header } from "./components/Header";
import { ROUTES } from "./services/routes";
import { Reservation } from "./pages/Reservation/Reservation";
import { Ajout } from "./pages/Ajout/Ajout";
import { About } from "./pages/About/About";
import { Photos } from "./pages/Photos/Photos";
import { Settings } from "./pages/Settings/Settings";
import { Consignes } from "./pages/Consignes/Consignes";
import { isLegit, groups, setSection } from "./services/user";
import { getThat } from "./services/apiUtils";
import { CustomModal } from "./components/CustomModal";
import Form from "react-bootstrap/Form";

class App extends React.Component {
  state = {
    userData: false,
    toLogin: false,
  };

  modal = React.createRef();

  componentDidMount = () => {
    this.getUserInfo();
  };

  getUserInfo = () => {
    getThat("/me/").then((data) => {
      if (data.success) {
        // si la section de l'utilisateur n'est pas définie, on affiche une
        // modal pour lui la demander
        if (!data.data.section) {
          let sections = groups.filter((e) => e.isSection);
          this.modal.current.show(
            "Indique ta section",
            <>
              <Form.Group controlId="section">
                <Form.Label>Sélectionne ta section :</Form.Label>
                <Form.Control as="select">
                  {Object.keys(sections).map((key) => (
                    <option value={sections[key].name} key={sections[key].name}>
                      {sections[key].text}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </>,
            "Enregistrer ma section",
            this.validateModalSection,
            false
          );
        } else {
          // si les données sont arrivées et que la section est définie
          // console.log("User: ", data.data);
          this.setState({ userData: data.data });
        }
      }
    });
  };

  validateModalSection = (args, callback) => {
    // console.log(args.target.section.value);
    setSection(args.target.section.value).then((result) => {
      callback();
      this.getUserInfo();
    });
  };

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Header userData={this.state.userData} />
          <Routes>
            <Route
              path={ROUTES.HOME}
              element={<Home userData={this.state.userData} />}
            />
            {isLegit(this.state.userData) && (
              <>
                <Route
                  path={ROUTES.RESERVATION}
                  element={<Reservation userData={this.state.userData} />}
                />
                <Route
                  path={ROUTES.PHOTOS}
                  element={<Photos userData={this.state.userData} />}
                />
                {(this.state.userData.is_staff ||
                  this.state.userData.is_respo_studio) && (
                  <>
                    <Route
                      path={ROUTES.AJOUT}
                      element={<Ajout userData={this.state.userData} />}
                    />
                  </>
                )}
                <Route path={ROUTES.ABOUT} element={<About />} />
                <Route path={ROUTES.CONSIGNES} element={<Consignes />} />
                <Route path={ROUTES.SETTINGS} element={<Settings />} />
              </>
            )}
          </Routes>
        </div>
        <CustomModal ref={this.modal} />
      </BrowserRouter>
    );
  }
}

export default App;
