import React from "react";
import Alert from "react-bootstrap/Alert";

export class Messages extends React.Component {
  state = {
    messages: [],
  };

  printError = (text = "Une erreur inconnue s'est produite") => {
    let prevVar = this.state.messages;
    prevVar.push({ text: text, variant: "danger" });
    this.setState({ messages: prevVar });
  };

  printSuccess = (text = "Quelque chose a réussi") => {
    let prevVar = this.state.messages;
    prevVar.push({ text: text, variant: "success" });
    this.setState({ messages: prevVar });
  };

  render() {
    if (this.state.messages.length === 0) {
      return <div></div>;
    }

    return (
      <div>
        {this.state.messages.map(function (message, id) {
          return (
            <Alert key={id} variant={message.variant}>
              {message.text}
            </Alert>
          );
        })}
      </div>
    );
  }
}
