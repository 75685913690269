import { sendThat } from "./apiUtils";

export const setSection = (section) => sendThat(`/setsection/${section}`);

export const isLegit = (userData) =>
  userData &&
  (userData.is_right_promo || userData.username === "jeremy.barande");

export const groups = [
  { name: "all", text: "Toutes les sections", isSection: false },
  { name: "aviron", text: "Aviron", isSection: true },
  { name: "badminton", text: "Badminton", isSection: true },
  { name: "basket", text: "Basket", isSection: true },
  { name: "boxe", text: "Boxe", isSection: true },
  { name: "crossfit", text: "Crossfit", isSection: true },
  { name: "equitation", text: "Equitation", isSection: true },
  { name: "escalade", text: "Escalade", isSection: true },
  { name: "escrime", text: "Escrime", isSection: true },
  { name: "foot", text: "Foot", isSection: true },
  { name: "hand", text: "Hand", isSection: true },
  { name: "judo", text: "Judo", isSection: true },
  { name: "natation", text: "Natation", isSection: true },
  { name: "raid", text: "Raid", isSection: true },
  { name: "rugby", text: "Rugby", isSection: true },
  { name: "tennis", text: "Tennis", isSection: true },
  { name: "volley", text: "Volley", isSection: true },
  { name: "binet", text: "Binet", isSection: false },
  { name: "strass", text: "Strass", isSection: false },
];
