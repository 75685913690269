import { API_URL } from "../config";

export const redirectToLoginAndComeBack = () =>
  window.location.replace(`${API_URL}/login?next=${window.location.href}`);

export const sendThat = (url) =>
  fetch(API_URL + url, {
    credentials: "include",
    mode: "cors",
    method: "post",
  }).then((response) => {
    switch (response.status) {
      case 403:
        redirectToLoginAndComeBack();
        return { success: false, data: "" };
      case 200:
        return response.text().then((text) => ({ success: true, data: text }));
      default:
        return response.text().then((text) => ({ success: false, data: text }));
    }
  });

export const getThat = (url) =>
  fetch(API_URL + url, {
    credentials: "include",
    mode: "cors",
  }).then((response) => {
    switch (response.status) {
      case 403:
        redirectToLoginAndComeBack();
        return { success: false, data: {} };
      case 200:
        return response.json().then((data) => ({ success: true, data }));
      default:
        return response.json().then((data) => ({ success: false, data }));
    }
  });
