import { sendThat, getThat } from "./apiUtils";

export const createCreneau = (start, end, section) =>
  sendThat(`/creer/${section}/${start}/${end}`);

export const deleteCreneau = (creneauId) => sendThat(`/supprime/${creneauId}`);

export const getCreneaux = () =>
  getThat(`/creneaux/0`).then(({ success, data }) => ({
    success,
    data: success ? mapApiToDisplayCreneaux(data) : data,
  }));

export const getCreneauxR = () =>
  getThat(`/creneaux/0`).then(({ success, data }) => ({
    success,
    data: success ? mapApiToDisplayCreneaux(data) : data,
  }));
export const getCreneauxA = () =>
  getThat(`/creneaux/1`).then(({ success, data }) => ({
    success,
    data: success ? mapApiToDisplayCreneaux(data) : data,
  }));
export const mapApiToDisplayCreneaux = (apiCreneaux) => {
  // console.log(apiCreneaux);
  return apiCreneaux.map((apiCreneau) => ({
    id: apiCreneau.id,
    start: apiCreneau.start_at,
    end: apiCreneau.end_at,
    author: apiCreneau.author,
    reservation: apiCreneau.reservation,
    isBinet: apiCreneau.isBinet,
    section: apiCreneau.section,
  }));
};

//supprime tous les creneaux dans la même journée que celui passé en parametre
export const deleteDayCreneau = (creneauId) =>
  sendThat(`/supprimejournee/${creneauId}`);
