import React from "react";
import Container from "react-bootstrap/Container";
import { ROUTES } from "../../services/routes";
import { Link } from "react-router-dom";
import "./Home.css";
import "./FadeIn.css";
import bg_img from "./mascotte.jpg";
import { isLegit } from "../../services/user";
import { APV_URL } from "../../config";

export class Home extends React.Component {
  render = () => {
    return (
      <Container>
        <div className="fadeinelt">
          <div class="row">
          <div class="col-sm-6">
            <div class="toptitle">Album Promo</div>
            <div class="bottomtitle">
              Viens te faire prendre en photo et vidéo
              <br />
              {isLegit(this.props.userData) && (
                <>
                  Réserve ton créneau photo{" "}
                  <Link to={ROUTES.RESERVATION}>ici</Link>
                </>
              )}
              {!this.props.userData && (
                <>Redirection vers la page de connexion...</>
              )}
              {this.props.userData && !isLegit(this.props.userData) && (
                <>
                  Les APV sont disponibles en ligne <a href={APV_URL}>ici</a>
                </>
              )}
            </div>
          </div>
            <div class="col-sm-6">
              <img class="backgroundimg" src={bg_img} alt="bg" />
            </div>
          </div>
        </div>
      </Container>
    );
  };
}
