import React from "react";
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./Consignes.css";
import { API_URL } from "../../config";

export const Consignes = () => {
  return (
    <Container>
      <Row style={{ marginBottom: "1em" }}>
        <h1>Consignes pour faire vos pages AP</h1>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Page binet</Accordion.Header>
            <Accordion.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>1 ou 2 pages</ListGroup.Item>
                <ListGroup.Item>
                  25&nbsp;cm de largeur et 22&nbsp;cm de hauteur (49&nbsp;cm de
                  largeur pour les double pages), 5&nbsp;mm de marges. Les
                  marges ne doivent pas être vides ni contenir des éléments
                  importants, elles servent de sécurité à l'impression et la
                  découpe.
                </ListGroup.Item>
                <ListGroup.Item>
                  Exporter en &lt;binet&gt;.pdf (dans le cas où il y a deux
                  pages, dans le même fichier ou deux fichiers séparés)
                </ListGroup.Item>
                <ListGroup.Item>
                  Définition minimale 300 dpi/ppi, format <b>CMYK</b> et non RGB
                </ListGroup.Item>
                <ListGroup.Item>
                  {}
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Page section</Accordion.Header>
            <Accordion.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  1 page et demi (la dernière partie est un bandeau avec le nom
                  de la section)
                </ListGroup.Item>
                <ListGroup.Item>
                  36,5&nbsp;cm de largeur et 22&nbsp;cm de hauteur, 5&nbsp;mm de
                  marges. Les marges ne doivent pas être vides ni contenir des
                  éléments importants, elles servent de sécurité à l'impression
                  et la découpe.
                </ListGroup.Item>
                <ListGroup.Item>Exporter en &lt;section&gt;.pdf</ListGroup.Item>
                <ListGroup.Item>
                  Définition minimale 300 dpi/ppi, format <b>CMYK</b> et non RGB
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Les logiciels</Accordion.Header>
            <Accordion.Body>
              On vous conseille d'utiliser Photoshop ou Microsoft Publisher
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h3>Photoshop</h3>
                  Créez une page au bon format et sélectionnez CMYK. Au moment
                  d'exporter en pdf, pour conserver la définition parfaitement,
                  choisissez{" "}
                  <Badge bg="light" text="dark">
                    Save as...
                  </Badge>{" "}
                  et ensuite{" "}
                  <Badge bg="light" text="dark">
                    Photoshop PDF
                  </Badge>
                  . Une nouvelle fenêtre s'ouvrira, mettez les paramètres
                  optimaux, décochez "optimiser pour le web" et voilà, c'est
                  parfait.
                </ListGroup.Item>
                <ListGroup.Item>
                  <h3>Microsoft Publisher</h3>
                  Il a l'avantage de s'utiliser comme PowerPoint et fait partie
                  de la suite Office. Créer une page avec des dimensions
                  personnalisées et les bonnes marges. Pour exporter :{" "}
                  <Badge bg="light" text="dark">
                    Fichiers &gt; Exporter &gt; Créer PDF/XPS &gt; Options &gt;
                    Presse commerciale
                  </Badge>
                  . Il faut ensuite convertir le fichier au profil de couleurs
                  CMYK en utilisant l'outil en-dessous ou ghostscript.
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
      <Row>
        <h1>RGB &rarr; CMYK</h1>
        <Form
          method="post"
          encType="multipart/form-data"
          action={API_URL + "/consignes/form"}
        >
          <Form.Group className="mb-3">
            <Form.Label>Fichier à convertir</Form.Label>
            <Form.Control
              type="file"
              name="pdf_file"
              accept="application/pdf"
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Valider
          </Button>
        </Form>
      </Row>
    </Container>
  );
};
