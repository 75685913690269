import React from "react";
import { Messages } from "../../components/Messages";
import { CustomModal } from "../../components/CustomModal";
import Container from "react-bootstrap/Container";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import frLocale from "@fullcalendar/core/locales/fr";
import { getCreneauxR } from "../../services/creneau";
import { reserveCreneau, annuleReservation } from "../../services/reservation";
import { readableName } from "../../services/readable";
import "./calendar.css";

const formatDate = (date) =>
  new Date(date).toLocaleDateString("fr-FR", {
    weekday: "long",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timezone: "Europe/Paris",
  });

export class Reservation extends React.Component {
  state = {
    events: [],
    selectedEvent: false,
  };

  modal = React.createRef();

  messages = React.createRef();

  componentDidMount = () => {
    this.refreshCreneaux();
  };

  //FONCTIONS UTILES AU MODAL

  refreshCreneaux = (callback = () => null) => {
    //callback sera executé une fois que les créneaux seront récupérés
    getCreneauxR().then((result) => {
      if (result.success) {
        // console.log("Créneaux :", result.data);
        this.setState({
          events: result.data,
        });
      } else {
        this.messages.current.printError(
          "Les créneaux n'ont pas pu être rafraichis, merci de recharger la page"
        );
      }
      callback();
    });
  };

  eventClick = ({ event }) => {
    let selectedEvent = this.getEvent(event.id);
    if (selectedEvent) {
      this.setState({ selectedEvent: selectedEvent });
      if (
        this.isEventAvailable(selectedEvent) ||
        this.isEventBinet(selectedEvent)
      ) {
        if (
          this.getEventsOn(this.isEventReservedByUserExceptBinet).length ===
            0 &&
          this.isEventBinet(selectedEvent) === false
        ) {
          const { end, start } = selectedEvent;
          this.modal.current.show(
            "Es-tu sûr de vouloir réserver ce créneau ?",
            <>
              Tu devras te rendre au studio entre le <b>{formatDate(start)}</b>{" "}
              et le <b>{formatDate(end)}</b>.<br />
              Tu seras photographié(e) par {readableName(selectedEvent.author)}.
            </>,
            "Valider le créneau",
            this.validateModalReservation
          );
        } else if (this.isEventBinet(selectedEvent) === true) {
          const { end, start } = selectedEvent;
          this.modal.current.show(
            "Es-tu sûr de vouloir réserver ce créneau binet ?",
            <>
              Vous devrez vous rendre au studio entre le{" "}
              <b>{formatDate(start)}</b> et le <b>{formatDate(end)}</b>.<br />
              Vous serez photographiés par {readableName(selectedEvent.author)}.
            </>,
            "Valider le créneau",
            this.validateModalReservation
          );
        } else {
          let date = this.getEventsOn(this.isEventReservedByUserExceptBinet)[0]
            .start;
          this.modal.current.show(
            "Supprime ton créneau existant",
            "Tu as déjà un créneau réservé pour le " + formatDate(date),
            "Valider",
            (args, callback) => callback()
          );
        }
      } else if (this.isEventReservedByUser(selectedEvent)) {
        this.modal.current.show(
          "Annulation du créneau",
          "Es-tu sûr de vouloir annuler ton créneau ?",
          "Annuler ce créneau",
          this.validateModalCancel
        );
      }
    } else {
      this.messages.current.printError();
    }
  };

  validateModalReservation = (args, callback) => {
    reserveCreneau(this.state.selectedEvent.id).then((result) => {
      if (result.success) {
        this.messages.current.printSuccess(
          "Le créneau a bien été réservé ! N'oublie pas de venir avec ton GU, et n'hésite pas à ramener des objets de ta vie, comme des t-shirts de binets, et de ramener des potes !"
        );
      } else {
        if (result.data === "") {
          this.messages.current.printError(
            "Le créneau n'a pas pu être réservé. Merci de réessayer"
          );
        } else {
          this.messages.current.printError(result.data);
        }
      }
      this.refreshCreneaux(() => {
        callback();
      });
    });
  };

  validateModalCancel = (args, callback) => {
    annuleReservation(this.state.selectedEvent.id).then((result) => {
      if (result.success) {
        this.messages.current.printSuccess("Le créneau a bien été annulé !");
      } else {
        if (result.data === "") {
          this.messages.current.printError(
            "La réservation n'a pas pu être annulée"
          );
        } else {
          this.messages.current.printError(result.data);
        }
      }
      this.refreshCreneaux(() => {
        callback();
      });
    });
  };

  //FONCTIONS EN RELATION AVEC LES CRENEAUX

  isEventAvailable = (event) =>
    event.reservation === false && this.isEventRightSection(event);

  isEventReservedByUser = (event) =>
    event.reservation !== false &&
    event.reservation.by.id === this.props.userData.id;

  isEventReservedByUserExceptBinet = (event) =>
    event.reservation !== false &&
    event.reservation.by.id === this.props.userData.id &&
    event.section !== "binet";

  isEventRightSection = (event) => {
    return (
      !event.section ||
      event.section === this.props.userData.section ||
      event.section === "all"
    );
  };

  isEventBinet = (event) => {
    return event.section === "binet" && event.reservation === false;
  };

  isEventUnavailable = (event) =>
    (event.reservation !== false &&
      event.reservation.by.id !== this.props.userData.id) ||
    (this.isEventRightSection(event) === false &&
      this.isEventBinet(event) === false &&
      ((event.reservation !== false &&
        event.reservation.by.id !== this.props.userData.id) ||
        event.reservation === false));

  getEvent = (idEvent) => {
    if (!this.state.events) {
      return false;
    }
    return this.state.events.filter((e) => e.id === Number(idEvent))[0];
  };

  getEventsOn = (fun) => this.state.events.filter((e) => fun(e));
  getEventSources = () => {
    if (!this.state.events || !this.props.userData.section) {
      return [];
    } else {
      return [
        {
          events: this.getEventsOn(this.isEventAvailable),
          color: "green",
        },
        {
          events: this.getEventsOn(this.isEventUnavailable),
          color: "#ED2939",
        },
        {
          events: this.getEventsOn(this.isEventReservedByUser),
          color: "blue",
        },
        {
          events: this.getEventsOn(this.isEventBinet),
          color: "yellow",
        },
      ];
    }
  };

  render = () => {
    return (
      <Container>
        <CustomModal ref={this.modal} />
        <h1>Réservation d'un créneau studio</h1>
        <Messages ref={this.messages} />
        <div>
          <FullCalendar
            initialView="timeGridWeek"
            headerToolbar={{
              left: "title",
              center: "",
              right: "today prev,next",
            }}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            weekends={true}
            eventSources={this.getEventSources()}
            eventClick={this.eventClick}
            locale={frLocale}
            allDaySlot={false}
          />
        </div>
      </Container>
    );
  };
}
