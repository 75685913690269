import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";

export class CustomModal extends React.Component {
  state = {
    closeModalAuthorized: true,
    show: false,
    head: "",
    body: "",
    button: { disabled: false, inner: "" },
    validation: (args, callback) => callback(),
  };

  show = (head, body, button, validation, dissmissable = true) => {
    this.setState({
      head: head,
      body: body,
      button: { disabled: false, inner: button },
      validation: validation,
      closeModalAuthorized: dissmissable,
      show: true,
    });
  };

  closeModal = () => {
    if (this.state.closeModalAuthorized) {
      this.setState({ show: false });
      return true;
    }
    return false;
  };

  validateModal = (args) => {
    args.preventDefault();
    this.setState({
      button: {
        inner: <Spinner animation="border" />,
        disabled: true,
      },
      closeModalAuthorized: false,
    });
    this.state.validation(args, () => {
      this.setState({ closeModalAuthorized: true, show: false });
    });
  };

  render = () => (
    <Modal
      animation={false}
      show={this.state.show}
      onHide={this.closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered="centered"
    >
      <Form onSubmit={this.validateModal}>
        <Modal.Header closeButton={this.state.closeModalAuthorized}>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.state.head}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.state.body}</Modal.Body>
        <Modal.Footer>
          <Button type="submit" disabled={this.state.button.disabled}>
            {this.state.button.inner}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

/* Usage example :
export class Test extends React.Component {

  modal = React.createRef();

  componentDidMount = () => {
    this.modal.current.show(
      "This is the Head",
      "This is the Body",
      "This is the button",
      callback => {
        console.log("modal validated");
        callback();
      }
    );
  };

  render = () => (
    <Container>
      <CustomModal ref={this.modal} />
    </Container>
  );
}
*/
