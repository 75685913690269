import React from "react";
import { getThat } from "../../services/apiUtils";
import { Messages } from "../../components/Messages";
import Container from "react-bootstrap/Container";
import Lightbox from "react-lightbox-component";
import "./Photos.css"; //custom adaptation of original css

export class Photos extends React.Component {
  state = {
    photos: [],
    zip_photos: "photos",
  };

  messages = React.createRef();

  componentDidMount = () => {
    this.getPhotos();
  };

  getPhotos = () => {
    getThat("/storage/zip-my-photos").then(({ success, data }) => {
      if (success) {
        this.setState({ zip_photos: data.url });
      } else {
        this.messages.current.printError(
          "Le fichier zip des photos n'a pas pu être récupéré !"
        );
      }
    });
    getThat("/storage/my-photos").then(({ success, data }) => {
      if (success && data.length !== 0) {
        this.setState({ photos: data.map((e) => ({ src: e.url })) });
      } else {
        this.messages.current.printError(
          "Les photos n'ont pas pu être récupérées !"
        );
      }
    });
  };

  render = () => {
    return (
      <Container>
        <h1>
          Mes photos AP - <a href={this.state.zip_photos}>Tout télécharger</a>
        </h1>
        <Messages ref={this.messages} />
        <Lightbox
          images={this.state.photos}
          thumbnailWidth="200px"
          thumbnailHeight="200px"
        />
      </Container>
    );
  };
}
