import React from "react";
import Container from "react-bootstrap/Container";
import FullCalendar from "@fullcalendar/react";
import { CustomModal } from "../../components/CustomModal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import frLocale from "@fullcalendar/core/locales/fr";
import Toggle from "react-toggle";
import "./react-toggle.css";
import { Messages } from "../../components/Messages";
import {
  getCreneauxA,
  deleteCreneau,
  deleteDayCreneau,
  createCreneau,
} from "../../services/creneau";
import { readableName, readableBinet } from "../../services/readable";
import { groups } from "../../services/user";
import "./calendar.css";

const formatDate = (date) =>
  new Date(date).toLocaleDateString("fr-FR", {
    weekday: "long",
    month: "long",
    day: "numeric",
    timezone: "Europe/Paris",
  });

export class Ajout extends React.Component {
  state = {
    events: [],
    selectedEvent: false,
    selectedDate: false,
    displayOtherEvents: false,
  };

  modal = React.createRef();

  messages = React.createRef();

  componentDidMount = () => {
    this.refreshCreneaux();
  };

  refreshCreneaux = (callback = () => null) => {
    //callback sera executé une fois que les créneaux seront récupérés
    getCreneauxA().then((result) => {
      if (result.success) {
        this.setState({ events: result.data });
        // console.log("Créneaux :", this.state.events);
      } else {
        this.messages.current.printError(
          "Les créneaux n'ont pas pu être rafraichis, merci de recharger la page"
        );
      }
      callback();
    });
  };

  dateClick = (args) => {
    this.setState({ selectedDate: args.date });
    let dateInit = args.date;
    let dateFin = new Date(args.date);
    dateFin.setHours(dateFin.getHours() + 1);
    let hourList = Array.from(Array(24).keys());
    let minuteList = ["00", "15", "30", "45"];
    let selecteurSection = React.createRef();
    this.modal.current.show(
      "Création de créneaux",
      <>
        <Row ref={selecteurSection}>
          <Col md="3">
            <Form.Label>Créer ces créneaux pour :</Form.Label>
          </Col>
          <Col md="5">
            <Form.Group controlId="section">
              <Form.Control
                as="select"
                defaultValue={this.props.userData.section}
              >
                {Object.keys(groups).map((key) => (
                  <option value={groups[key].name} key={groups[key].name}>
                    {groups[key].text}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>Le {formatDate(dateInit)}</Col>
        </Row>
        <Row>
          <Col md="auto" style={{ marginTop: "7px" }}>
            de
          </Col>
          <Col md="auto">
            <Form.Group style={{ width: "65px" }} controlId="heureInit">
              <Form.Control defaultValue={dateInit.getHours()} as="select">
                {hourList.map((val) => (
                  <option key={val}>{val}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md="auto" style={{ marginTop: "7px" }}>
            h
          </Col>
          <Col md="auto">
            <Form.Group style={{ width: "65px" }} controlId="minuteInit">
              <Form.Control defaultValue={dateInit.getMinutes()} as="select">
                {minuteList.map((val) => (
                  <option key={val}>{val}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col style={{ marginTop: "7px" }}>mins</Col>
        </Row>

        <Row>
          <Col md="auto" style={{ marginTop: "7px" }}>
            à
          </Col>
          <Col md="auto">
            <Form.Group controlId="heureFin">
              <Form.Control defaultValue={dateFin.getHours()} as="select">
                {hourList.map((val) => (
                  <option key={val}>{val}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md="auto" style={{ marginTop: "7px" }}>
            h
          </Col>
          <Col md="auto">
            <Form.Group controlId="minuteFin">
              <Form.Control defaultValue={dateFin.getMinutes()} as="select">
                {minuteList.map((val) => (
                  <option key={val}>{val}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col style={{ marginTop: "7px" }}>mins</Col>
        </Row>
      </>,
      "Valider",
      this.validateFormCreation
    );
  };

  eventClick = ({ event }) => {
    let selectedEvent = this.getEvent(event.id);
    if (selectedEvent) {
      this.setState({ selectedEvent: selectedEvent });
      //si on clique sur un evt qui n'est pas à l'user log on redirige sur la fonction qui permet de créer des créneaux
      if (!this.isCreneauByUser(selectedEvent)) {
        this.modal.current.show(
          "Ce créneau est déjà utilisé par un autre respo !",
          "Ce créneau a été créé par " + readableName(selectedEvent.author),
          "Valider",
          (args, callback) => callback()
        );
        return;
      }
      if (this.isEventAvailable(selectedEvent)) {
        this.modal.current.show(
          "Ce créneau n'a pas encore été réservé !",
          <Row>
            <Col>
              <Form.Group controlId="toutSupprimer">
                <Form.Check
                  type="checkbox"
                  label="Supprimer tous les autres créneaux non réservés de la journée"
                />
              </Form.Group>
            </Col>
          </Row>,
          "Supprimer",
          this.validateFormSuppression
        );
        return;
      } else {
        let additionnalText = "";
        if (selectedEvent.isBinet) {
          additionnalText =
            " pour le binet '" +
            readableBinet(selectedEvent.reservation.binet) +
            "' dans le lieu '" +
            selectedEvent.reservation.lieu +
            "'";
        }
        this.modal.current.show(
          "Ce créneau a été réservé !",
          "Ce créneau a été réservé par " +
            readableName(selectedEvent.reservation.by.username) +
            additionnalText,
          "Valider",
          (args, callback) => callback()
        );
        return;
      }
    }
  };

  validateFormCreation = (args, callback) => {
    if (!this.state.selectedDate) {
      this.messages.current.printError();
      callback();
      return false;
    }
    let start = new Date(this.state.selectedDate);
    let end = new Date(this.state.selectedDate);
    start.setHours(args.target.heureInit.value);
    start.setMinutes(args.target.minuteInit.value);
    end.setHours(args.target.heureFin.value);
    end.setMinutes(args.target.minuteFin.value);
    let startTimestamp = start.getTime();
    let endTimestamp = end.getTime();
    //on traite le cas ou l'heure de fin correspondait en fait au lendemain
    if (endTimestamp < startTimestamp) {
      endTimestamp += 86400000;
    }
    let section = args.target.section.value;
    createCreneau(startTimestamp, endTimestamp, section).then((result) => {
      if (result.success) {
        this.messages.current.printSuccess("Le créneau a bien été créé");
      } else {
        this.messages.current.printError(result.data);
      }
      this.refreshCreneaux(() => {
        callback();
        return;
      });
    });
  };

  validateFormSuppression = (args, callback) => {
    if (args.target.toutSupprimer.checked) {
      deleteDayCreneau(this.state.selectedEvent.id).then((result) => {
        if (result.success) {
          this.messages.current.printSuccess(
            "Le créneau a été bien supprimé !"
          );
        } else {
          this.messages.current.printError(result.data);
        }
        this.refreshCreneaux(() => {
          callback();
          return;
        });
      });
    } else {
      deleteCreneau(this.state.selectedEvent.id).then((result) => {
        if (result.success) {
          this.messages.current.printSuccess(
            "Le créneau a été bien supprimé !"
          );
        } else {
          this.messages.current.printError(result.data);
        }
        this.refreshCreneaux(() => {
          callback();
          return;
        });
      });
    }
  };

  //indique si l'evenement est créé par l'utilisateur actif
  isCreneauByUser = (event) => event.author === this.props.userData.username;

  //indique si l'evenement est deja réservé
  isEventAvailable = (event) => event.reservation === false;

  isCreneauAvailable = (event) =>
    event.reservation === false &&
    event.author === this.props.userData.username;

  isCreneauUnavailable = (event) =>
    event.reservation !== false &&
    event.author === this.props.userData.username;

  isCreneauByAnotherOneAvailable = (event) =>
    event.author !== this.props.userData.username &&
    event.reservation === false;

  isCreneauByAnotherOneUnavailable = (event) =>
    event.author !== this.props.userData.username &&
    event.reservation !== false;

  getEvent = (idEvent) => {
    if (!this.state.events) {
      return false;
    }
    return this.state.events.filter((e) => e.id === Number(idEvent))[0];
  };

  getEventsOn = (fun) => this.state.events.filter((e) => fun(e));

  getEventSources = () => {
    if (!this.state.events || !this.props.userData.section) {
      return [];
    } else {
      return [
        this.state.displayOtherEvents && {
          events: this.getEventsOn(this.isCreneauByAnotherOneAvailable),
          color: "grey",
        },
        this.state.displayOtherEvents && {
          events: this.getEventsOn(this.isCreneauByAnotherOneUnavailable),
          color: "red",
        },
        {
          events: this.getEventsOn(this.isCreneauAvailable),
          color: "blue",
        },
        {
          events: this.getEventsOn(this.isCreneauUnavailable),
          color: "green",
        },
      ];
    }
  };

  render = () => {
    return (
      <Container>
        <CustomModal ref={this.modal} />
        <h1>Gestion des créneaux</h1>
        <h2>Le studio est disponible en semaine !</h2>
        <Messages ref={this.messages} />
        <label>
          <Toggle
            defaultChecked={this.state.displayOtherEvents}
            onChange={() =>
              this.setState({
                displayOtherEvents: !this.state.displayOtherEvents,
              })
            }
          />
          <span style={{ position: "relative", left: "5px", bottom: "5px" }}>
            Afficher les créneaux des autres respos
          </span>
        </label>
        <div>
          <FullCalendar
            initialView="timeGridWeek"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "timeGridWeek,timeGridDay,listWeek",
            }}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              listPlugin,
              interactionPlugin,
            ]}
            weekends={true}
            eventSources={this.getEventSources()}
            eventClick={this.eventClick}
            dateClick={this.dateClick}
            locale={frLocale}
            allDaySlot={false}
          />
        </div>
      </Container>
    );
  };
}
